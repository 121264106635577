import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { ContainerRow } from '@src/components/styled';
import SubscriptionPlanInfo from './SubscriptionPlanInfo';
import { checkPlanFee } from './utils';
const PlanListContainer = styled(ContainerRow)((props) => ({
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const SubscriptionPlanList = ({ plansList, onPlanDowngrade, onPlanUpgrade, }) => {
    const { t } = useTranslation();
    const { plansListDict, currentPlan: currentPlanVal } = useMemo(() => {
        const plansListDictValue = {};
        let currentPlanValue;
        plansList.forEach((plan) => {
            if (plan.isSelected) {
                currentPlanValue = plan;
            }
            plansListDictValue[plan.id] = Object.assign({}, plan);
        });
        return {
            plansListDict: plansListDictValue,
            currentPlan: currentPlanValue,
        };
    }, [plansList]);
    const planDescriptionColumn = {
        title: '',
        description: '',
        options: {
            limits: t('settings.tabs.subscription.subscription_plan.copy_trading_limit'),
            spotCopy: t('settings.tabs.subscription.subscription_plan.spot_copy'),
            margin: t('settings.tabs.subscription.subscription_plan.margin'),
            futures: t('settings.tabs.subscription.subscription_plan.futures'),
            support: t('settings.tabs.subscription.subscription_plan.support'),
        },
        id: 'INFO',
        fee: 0,
    };
    const handlePlanChange = (planId) => {
        const selectedPlanFee = checkPlanFee(plansListDict[planId]);
        const currentPlanFee = checkPlanFee(currentPlanVal);
        const isSelectedPriceHigher = typeof selectedPlanFee === 'number' && typeof currentPlanFee === 'number' &&
            selectedPlanFee > currentPlanFee;
        const isUpgrade = currentPlanVal.isFree || isSelectedPriceHigher;
        if (isUpgrade) {
            onPlanUpgrade(plansListDict[planId]);
        }
        else {
            onPlanDowngrade(currentPlanVal, plansListDict[planId]);
        }
    };
    return (_jsxs(PlanListContainer, { children: [_jsx(SubscriptionPlanInfo, { plan: planDescriptionColumn, flex: 1 }, planDescriptionColumn.id), plansList.map((plan) => (_jsx(SubscriptionPlanInfo, { plan: plan, flex: 2, isDescriptionContainer: true, onSelect: handlePlanChange }, plan.id)))] }));
};
export default SubscriptionPlanList;
